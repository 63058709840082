import React, { useRef, useState } from 'react';
import './App.css';
import LogoPadrao from '../src/images/LogoPadrao.png';
import PresentationImage from '../src/images/FotoPessoal.jpg';
import ImagemWhatsApp from '../src/images/LogoWhatsApp.png';
import ImagemInstagram from '../src/images/Icone-Instagram.png';
import IconeWhatsApp from '../src/images/Icone-WhatsApp.png';
import IconeLinkedin from '../src/images/Icone-Linkedin.png';
import IconeFaculdade from '../src/images/Faculdade.png';
import IconeBalanca from '../src/images/Balanca.png';
import IconeCursos from '../src/images/Livro.png';
import Apostrofo from '../src/images/apostrofo.png';
import FotoPessoalDois from '../src/images/FotoPessoalDois.jpg';
import FotoPessoalTres from '../src/images/FotoPessoalTres.jpg';
import FotoJutsica from '../src/images/FotoJustica.jpg';
import emailjs from '@emailjs/browser';
import { Slide, Fade } from "react-awesome-reveal";


function LandingPageFunction() {
  /* API Google Fontes */
  const linkElement = document.createElement('link');
  linkElement.rel = 'stylesheet';
  linkElement.href = 'https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap';
  document.head.appendChild(linkElement);
  /* API Email.js */
  const form = useRef();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const sendEmail = (e) => {

    e.preventDefault();

    emailjs
      .sendForm('service_unxaqsf', 'template_bfa6616', form.current, {
        publicKey: 'TvM_YqZ3Kuup5VN1V',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          // Esvaziar os campos do formulário após o envio bem-sucedido
          setFormData({
            name: '',
            email: '',
            message: ''
          });
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

  return (
    /* Começamos com a div da landing page ao todo */
    <div className="LandingPage">
      {/* Depois temos um header para determinar a barra na parte de cima do código */}
      <header className="LandingPage-header">
        {/* A logo no canto superior esquerdo dentro da barra (header) */}
        <img src={LogoPadrao} alt="Logo" className="rounded-image" />
      </header>

      {/* Primeiro container: Possui dois containers um do lado do outro, um para os textos de 
      apresentação e outro para foto */}
      <div class="container-wrapper">
        <Slide direction="left">
          <div class="container-left">
            <div class="presentation-container">
              <div class="presentation-text-top">
                <h2>Olá, sou</h2>
              </div>
              <div class="presentation-text-bottom">
                <p>Renata Oliveira</p>
              </div>
            </div>
            <div class="the-blocks">
              <div class="block-one">
                <div class="sub-block">
                  <div class="sub-block-one-pt1">
                    <p>TÉCNICA</p>
                  </div>
                  <div class="sub-block-two">
                    <p>em Gestão de Políticas Públicas</p>
                  </div>
                </div>
              </div>
              <div class="block-two">
                <div class="sub-block">
                  <div class="sub-block-one-pt1">
                    <p>MESTRE</p>
                  </div>
                  <div class="sub-block-two">
                    <p>em Mediação e Resolução de Conflitos</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="the-blocks">
              <div class="block-one">
                <div class="sub-block">
                  <div class="sub-block-one">
                    <p>5</p>
                  </div>
                  <div class="sub-block-two">
                    <p>ANOS ADVOGANDO</p>
                  </div>
                </div>
                <div class="sub-block-three-exception">
                  <p>
                    São anos dedicados a ajudar pessoas na busca pela justiça.
                  </p>
                </div>
              </div>
              <div class="block-two">
                <div class="sub-block">
                  <div class="sub-block-one">
                    <p>+10</p>
                  </div>
                  <div class="sub-block-two">
                    <p>ANOS DE EXPERIÊNCIA</p>
                  </div>
                </div>
                <div class="sub-block-three">
                  <p>
                    Com experiência de uma década em gestão administrativa e
                    integração estratégica de processos jurídicos.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Slide>
        <Slide direction="right">
          <div class="container-right">
            <div class="presentation-image">
              <img src={PresentationImage} alt="Foto Pessoal Renata Oliveira" class="personal-image" />
            </div>
          </div>
        </Slide>
      </div>

      {/* Bloco sobre quem é a profissional, o que ela faz e as suas formações*/}
      <div className="about-me-container">
        <div className="about-me-section">
          <Slide direction="left">
            <p>UM POUCO SOBRE MIM</p>
          </Slide>
        </div>
        <div className="about-me-text">
          <Slide direction="left">
            <p>
            Profissional com sólida formação em Gestão de Políticas Públicas e 
            Direito, combinada com uma vasta experiência de dez anos atuando na 
            área administrativa. Como advogada, os últimos cinco anos foram 
            dedicados à integração de gestão estratégica e processos jurídicos, 
            com foco especializado em direito cível e mediação de conflitos, o que 
            vem de uma trajetória robusta em escritórios contenciosos de massa onde 
            competências práticas na elaboração de peças processuais, contratos, e 
            condução de negociações são essenciais. Esta experiência garante uma 
            atuação abrangente e eficaz em diversas frentes do direito, o que é 
            ampliado para a área criminal por meio da atuação como conveniada da 
            Defensoria Pública do Estado de São Paulo, e do trabalho voluntário com 
            mulheres em situação de violência doméstica por meio da ONG 'As Justiceiras'. 
            Estes projetos refletem a missão enquanto advogada: disseminar informações 
            para que as pessoas possam tomar decisões conscientes e acessar a justiça de forma 
            equitativa.
            </p>
          </Slide>
        </div>
        <Fade>
            <div className="about-me-blocks">
              <div className="about-me-block-one">
                <img src={IconeFaculdade} alt="Ícone de Good Ware" className="imagem-faculdade" href="https://br.freepik.com/search" />
                <p className="initial">Formação</p>
                <p className="about-title">Técnica em Gestão de Políticas Públicas<br /><br />
                  Advogada formada em Direito pelas Faculdades Metropolitanas Unidas</p>
              </div>
              <div className="about-me-block-two">
                <img src={IconeCursos} alt="Icon by Freepik" className="imagem-pos-graduacao" href="https://www.freepik.com/icon/open-book_2702134#fromView=search&page=1&position=0&uuid=eabae85a-d63e-4eab-aef5-3b1574d98550" />
                <p className="initial">Pós-Graduação</p>
                <p className="about-title">Mestre em Resolução de Conflitos e Mediação<br /><br />
                  Pós-graduanda em Processo Penal</p>
              </div>
              <div className="about-me-block-three">
                <img src={IconeBalanca} alt="Ícone de Freepik" className="imagem-cursos" href="https://br.freepik.com/search" />
                <p className="initial">Profissão</p>
                <p className="about-title">Advogada autonôma nas áreas Cível e Criminal<br /><br />
                  Gerente Jurídica em Kurama Serviços e Transportes LTDA.</p>
              </div>
            </div>
        </Fade>
      </div>

      {/* Bloco sobre as áreas de atuação do profissional */}
      <div className="information-banner">
        <div className="information-banner-text">
          <Slide direction="left">
            <p>ÁREAS DE ATUAÇÃO</p>
          </Slide>
        </div>
        <div className="information-banner-blocks">
          <Slide direction="left">
            <div className="information-banner-block-one">
              <div className="information-banner-title">
                <p>Pessoa física e jurídica</p>
              </div>
              <div className="information-banner-block-text">
                <p>
                  Com expertise em defender tanto pessoas físicas quanto jurídicas,
                  trago comprometimento e habilidade para garantir os direitos e
                  interesses dos meus clientes perante a justiça.
                </p>
              </div>
            </div>
          </Slide>
          <Slide direction="up">
            <div className="information-banner-block-two">
              <div className="information-banner-title">
                <p>Cível e Criminal</p>
              </div>
              <div className="information-banner-block-text">
                <p>
                  Com vasta experiência na defesa tanto em questões cíveis quanto
                  criminais, estou preparada para oferecer suporte jurídico eficaz e
                  estratégico em todas as etapas do processo legal,
                  garantindo os melhores resultados para meus clientes.
                </p>
              </div>
            </div>
          </Slide>
          <Slide direction="right">
            <div className="information-banner-block-three">
              <div className="information-banner-title">
                <p>Direito do Consumidor</p>
              </div>
              <div className="information-banner-block-text">
                <p>
                  Com foco no cliente e conhecimento abrangente em direito,
                  estou preparada para defender seus direitos e garantir uma
                  resolução justa em qualquer questão relacionada ao consumo.
                </p>
              </div>
            </div>
          </Slide>
        </div>
      </div>

      {/* Bloco informativo sobre a missão, os valores e a visão do profissional */}
      <div class="second-information-banner">
        <div class="second-information-banner-title-one">
          <Slide direction="right">
            <p>MISSÃO</p>
          </Slide>
        </div>
        <div class="second-information-block-one">
          <div class="image-container-banner">
            <Slide direction="right">
              <img src={FotoPessoalDois} alt="Imagem Missão" />
            </Slide>
          </div>
          <div class="seconde-information-banner-mission">
            <Slide direction="right">
              <p>Defender o direito do cliente e a aplicação da justiça com respeito às diferenças,
                crenças e escolhas individuais, de forma comprometida e transparente.
              </p>
            </Slide>
          </div>
        </div>

        <div class="second-information-banner-title-two" >
          <Slide direction="left">
            <p>VISÃO</p>
          </Slide>
        </div>
        <div class="second-information-block-two">
          <div class="seconde-information-banner-vision">
            <Slide direction="left">
              <p>Tornar-se um escritório reconhecido pela excelência e individualidade
                na tratativa com o cliente, alinhando ascensão econômica e interesses sociais.
              </p>
            </Slide>
          </div>
          <div class="image-container-banner">
            <Slide direction="left">
              <img src={FotoJutsica} alt="Imagem Visão" />
            </Slide>
          </div>
        </div>

        <div class="second-information-banner-title-three">
          <Slide direction="right">
            <p>VALORES</p>
          </Slide>
        </div>
        <div class="second-information-block-three">
          <div class="image-container-banner">
            <Slide direction="right">
              <img src={FotoPessoalTres} alt="Imagem Valores" />
            </Slide>
          </div>
          <div class="seconde-information-banner-mission">
            <Slide direction="right">
              <p>Cada atividade desempenhada pelo escritório é baseada na transparência
                de informações, responsabilidade profissional, segurança jurídica e compatibilização de expectativas.
              </p>
            </Slide>
          </div>
        </div>
      </div>

      {/*Bloco de opiniões de clientes */}
      <div className="container-total">
        <div className="customers-say-container">
          <Slide direction="right">
            <div className="customers-say-title">
              <p>O QUE OS CLIENTES DIZEM</p>
            </div>
            </Slide>
            <div className="customers-say-blocks">
              <div className="customers-block">
                <div className="customers-image-container">
                  <img src={Apostrofo} width="30px" alt="Icon by Icon Mela" className="imagem-apostrofo" href="https://www.freepik.com/icon/quote_7350737#fromView=search&page=1&position=1&uuid=f443d4c8-1302-4ad6-a68f-4a0061b221c7" />
                </div>
                <div className="customers-text-container">
                  <p>
                    A Dra Renata é extremamente profissional, atenciosa, interessada na resolução dos assuntos,
                    sempre preocupada com o melhor para seus clientes, se empenhando ao máximo em cada detalhe.
                    Uma defensora de confiança, que fez a diferença em minha vida.
                  </p>
                </div>
                <div className="customers-name">
                  <p>- Suely Gorgati</p>
                </div>
              </div>
              <div className="customers-block">
                <div className="customers-image-container">
                  <img src={Apostrofo} width="30px" alt="Icon by Icon Mela" className="imagem-apostrofo" href="https://www.freepik.com/icon/quote_7350737#fromView=search&page=1&position=1&uuid=f443d4c8-1302-4ad6-a68f-4a0061b221c7" />
                </div>
                <div className="customers-text-container">
                  <p>
                    Ótima experiência.<br />
                    Advogada muito competente, atenciosa e totalmente qualificada para solucionar meu problema.
                    Você agora é meu contato de emergência!
                  </p>
                </div>
                <div className="customers-name">
                  <p>- Luan Rufino</p>
                </div>
              </div>
              <div className="customers-block">
                <div className="customers-image-container">
                  <img src={Apostrofo} width="30px" alt="Icon by Icon Mela" className="imagem-apostrofo" href="https://www.freepik.com/icon/quote_7350737#fromView=search&page=1&position=1&uuid=f443d4c8-1302-4ad6-a68f-4a0061b221c7" />
                </div>
                <div className="customers-text-container">
                  <p>
                    Pontualmente comprometida com o cliente. Sempre recomendo de olhos fechados.
                  </p>
                </div>
                <div className="customers-name">
                  <p>- Cynthia Kranholdt</p>
                </div>
              </div>
            </div>
        </div>
      </div>
      {/* Bloco para entrar em contato */}
      <div className="contact-container">
        <div className="contact-information">
          <div className="contact-top">
            <Slide direction="left">
              <p>Entre em contato:</p>
            </Slide>
          </div>
          <div className="contact-group">
            <div className="contact-title">
              <Slide>
              <p>Telefone: &nbsp; <a href="https://wa.me/5511982312948?text=Olá! Gostaria de saber mais informações." target="_blank" rel="noopener noreferrer" style={{color: 'white'}}>+55 11 982312948</a></p>
              </Slide>
            </div>
          </div>
          <div className="contact-group">
            <div className="contact-title">
              <Slide>
                <p>E-mail: <a href="mailto:reoliveirarufino@adv.oabsp.org.br" target="_blank" rel="noopener noreferrer" style={{color: 'white'}}>reoliveirarufino@adv.oabsp.org.br</a></p>
              </Slide>
            </div>
          </div>
        </div>

        <div className="contact-fields">
        <h2 className="contact-help" >Como posso te ajudar?</h2>
  <form ref={form} onSubmit={sendEmail}>
  <Slide direction="right">
    <div className="form-group">
      <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required placeholder="Nome" />
    </div>
    <div className="form-group">
      <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required placeholder="Email" />
    </div>
    <div className="form-group">
      <textarea id="message" name="message" value={formData.message} onChange={handleChange} required placeholder="Mensagem"></textarea>
    </div>
    <button type="submit">Enviar</button>
    </Slide>
  </form>
      </div>


      </div>
      <div className="footer">
        <Slide direction="right">
            <div className="social-medias">
              <a href="https://www.instagram.com/advocaciarenataoliveira/" target="_blank" rel="noopener noreferrer">
                <img src={ImagemInstagram} width="40px" alt="Fale conosco pelo Instagram (Ícone de Hight Quality Icons)" title="" />
              </a>
              {/* ÍCONE INTAGRAM -> <a href="https://www.flaticon.com/br/icones-gratis/logotipo-do-instagram" title="logotipo do instagram ícones">Logotipo do instagram ícones criados por Hight Quality Icons - Flaticon</a> */}
              <a href="https://wa.me/5511982312948?text=Olá! Gostaria de saber mais informações." target="_blank" rel="noopener noreferrer">
                <img src={IconeWhatsApp} width="40px" alt="Fale conosco pelo WhatsApp (Ícone de Hight Quality Iconst)" title="" />
              </a>
              {/* ÍCONE WHATSAPP -> <a href="https://www.flaticon.com/br/icones-gratis/whatsapp" title="whatsapp ícones">Whatsapp ícones criados por Hight Quality Icons - Flaticon</a> */}
              <a href="https://www.linkedin.com/in/reoliveirarufino/" target="_blank" rel="noopener noreferrer">
                <img src={IconeLinkedin} width="40px" alt="Conheça meu Linkedin (Ícone de Md Tanvirul Haque)" title="" />
              </a>
              {/* ÍCONE LINKEDIN -> <a href="https://www.flaticon.com/br/icones-gratis/linkedin" title="linkedin ícones">Linkedin ícones criados por Md Tanvirul Haque - Flaticon</a> */}
            </div>
            <p className="footer-text">&copy; 2024 Advogada Renata Oliveira. <br />Todos os direitos reservados a Regina Pompeo.</p>
            <p className="footer-text">Ícones by Freepik and Flaticon</p>
        </Slide>
      </div>
      <div className="WhatsApp">
        <a href="https://wa.me/5511982312948?text=Olá! Gostaria de saber mais informações."target="_blank" rel="noopener noreferrer">
          <img src={ImagemWhatsApp} width="60px" alt="Fale conosco pelo WhatsApp (Ícone de Fathema Khanom)" title="" />
          {/* ÍCONE INSTAGRAM MARSALA -> <a href="https://www.flaticon.com/br/icones-gratis/whatsapp" title="whatsapp ícones">Whatsapp ícones criados por Fathema Khanom - Flaticon</a> */}
        </a>
      </div>
    </div>

  );
}

export default LandingPageFunction;
